


















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ReportCardItem extends Vue {
  public name = "ReportCardItem";

  @Prop()
  public data!: any;
}
