












































import { Prop, Vue, Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Chart from "@/components/Charts/Chart.vue";
import { ChartData, ChartOptions } from "chart.js/auto";
import PrintButton from "@/components/Button/PrintButton.vue";
import DocumentButton from "@/components/Button/DocumentButton.vue";
import Hint from "@/views/AutomaticPaymentsOverview/Hint.vue";
import moment from "moment";

@Component({
  components: { Chart, FscSimpleCard, PrintButton, DocumentButton, Hint },
})
export default class ReportLearnPieChart extends Vue {
  public name = "ReportLearnPieChart";
  @Prop()
  public data!: any;

  @Prop({ type: Boolean })
  public loading!: boolean;

  @Prop()
  public filterData!: any;

  @Prop()
  public selectedType!: any;

  public chartColors = ["#f9b342", "#793b86", "#f9b342", "#169FDB", "#ea5b1b"];

  public get value() {
    if (!this.data) return [];
    let arr: any = [];
    arr.push(this.data.passedExamsPercentage);
    arr.push(this.data.notPassedExamsPercentage);
    return arr;
  }

  public get labels() {
    if (!this.data) return [];
    const arr: any = ["Bestanden %", "Nicht bestanden %"];
    return arr;
  }

  public get chartData(): ChartData {
    return {
      labels: this.labels,
      datasets: [
        {
          // label: this.value,
          data: this.value,
          backgroundColor: this.chartColors,
          hoverOffset: 20,
          borderWidth: 3,
        },
      ],
    };
  }

  public chartOptions: ChartOptions<"doughnut"> = {
    responsive: true,
    radius: 160,
    aspectRatio: 2,
    maintainAspectRatio: true,
    layout: {
      padding: 50,
    },
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
    },
  };
}
