






































import { Prop, Vue, Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Chart from "@/components/Charts/Chart.vue";
import { ChartData, ChartOptions } from "chart.js/auto";
import PrintButton from "@/components/Button/PrintButton.vue";
import DocumentButton from "@/components/Button/DocumentButton.vue";
import Hint from "@/views/AutomaticPaymentsOverview/Hint.vue";
import moment from "moment";

@Component({
  components: { Chart, FscSimpleCard, PrintButton, DocumentButton, Hint },
})
export default class ReportLearnVerticalChart extends Vue {
  public name = "ReportLearnVerticalChart";

  @Prop()
  public data!: any;

  @Prop({ type: Boolean })
  public loading!: boolean;

  @Prop()
  public filterData!: any;

  @Prop()
  public selectedType!: any;

  public chartColors = ["#169FDB", "#793b86", "#dc0c23", "#ea5b1b", "#f9b342"];

  public get value() {
    if (!this.data) return [];
    let arr: any = [];
    this.data.map((value: any) => {
      arr.push({
        y: value.averageKmPerLesson,
        x: value.drivingLessonType,
      });
    });
    return arr;
  }

  public get chartData(): ChartData {
    return {
      datasets: [
        {
          fill: true,
          showLine: true,
          tension: 0.3,
          label: "",
          data: this.value,
          backgroundColor: this.chartColors,
          borderColor: this.chartColors,
          pointBackgroundColor: this.chartColors,
          borderWidth: 1,
          borderRadius: 5,
        },
      ],
    };
  }

  public chartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },

    scales: {
      y: {
        ticks: {
          stepSize: 1,
          font: {
            weight: "bold",
            size: 13,
          },
        },
        beginAtZero: true,
      },
      x: {
        ticks: {
          font: {
            weight: "bold",
            size: 13,
          },
        },
      },
    },
  };
}
